.lightbox-inline {
}

.lightbox-external {
}

/* default colorbox hover */
a.modalImage, [data-rel="lightbox:"] {   
	@extend .marginBottom;
	display: table;
	position: relative;
	text-decoration: none;
	width: 100%;

	&[href*="qr"] { 
	width: auto;

	&:before,
	&:after {
		content: none;
	}
	}

	&:before,
	&:after {
	@extend %animatedTransform;
	pointer-events: none;
	}

	&:before {
	bottom: 0;
	color: rgba($light, 0.75);
	content: "+";
	display: block;
	font-family: sans-serif;
	font-size: 2rem;
	height: 2em;
	line-height: 1.8;
	position: absolute;
	right: 0px;
	text-align: center;
	text-shadow: rem(1px) rem(1px) rem(1px) rgba($dark, 0.8);
	transform: translate3d(0, 0, 0);
	width: 2em;
	z-index: 1;
	}

	&:after {
	bottom: 0;
	content: "";
	display: block;
	left: 0;
	opacity: 0;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 0;
	}

	&:hover,
	&:focus {
	&:before {
		bottom: 50%;
		font-size: 3rem;
		height: 1.5em;
		line-height: 1.5;
		right: 50%;
		transform: translate3d(50%, 50%, 0);
		width: 1.5em;
	}

	&:after {
		opacity: 0.5;
	}
	}
}