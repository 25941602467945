// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: large; // Breakpoint – switch to Desktop version

$desktopHover: true; // Set Hover to reach Submenus for Desktop (true, false)

// NAVIGATION
//////////////////////////////////////////////

$navBg: #f9f5f5; // Navigation Background
$navColor: $dark; // Navigation Link Color

@mixin navButton {
	// Default Link Styles
	padding: 1em rem($baseGap);
	text-decoration: none;
	color: $navColor;
}
%buttonHover {
	// Link Hover
	color: $secondary;
} 
%buttonActive {
	// Link Active
	color: $secondary; 
} 
%prevIcon {
	// Icon to previus Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);
	transform: rotateY(180deg);

	@include breakpoint($break) {
		transform:rotate(90deg);
	}
}
%nextIcon {
	// Icon to next Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);

	@include breakpoint($break) {
		transform:rotate(90deg);
	}
}
$closeIcon: map-get($iconMap, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$barHeight: 50px; // height (in px)
$barPos: top; // position (top, bottom)
$barHide: true; // if bar hides on scroll (true, false)

$barBg: $dark; // background-color
$barColor: $light; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";

// Navigation Styles
////
@include pageNavi {
	background-color: $navBg;
	z-index: 1001;
	top: 50px;
	.brandMobile, .desktopInfo{ 
		display:none;
	}
	.col{
		padding:0;
	}
	nav {
		@include ul {
			&.navi {

				li {
					@include navButtons {
						@include navButton;
					}
					@include hasSub {
						@include sub {
							background: $navBg;

							&:before {
								padding: 1em rem($baseGap);
								background-color: darken($navBg, 6%);
								width:100%;
								display:block; 
							}
						}
					}
					@include navBack {
					}
				}
			}
		}
	}
}

// Navigation Desktop Styles
////
@include breakpoint($break) {
	@include desktopVersionFunctionality;

	#pageNavi {
		padding:rem(60px) 0 rem(27px); 
		box-shadow: 2px 2px 25px 2px rgba(0, 0, 0, 0.2);
		@include breakpoint(huge) { 
			padding:0 0 rem(35px) 0; 
		}
		.col{
			padding: 5rem rem(18px) 0 rem(18px);  
		} 
		.brandMobile{
			display:block;
		} 
		.navCont{ 
			position: relative;
			z-index: 0;
			@include breakpoint(huge) {
				max-width: 65%;
			}
		}
		.phoneCont{
			position:absolute;
			top: 0;
			right: 0;
			z-index: 3;
			@include breakpoint(large) {
				max-width: 100%;
				flex-basis: 16.66667%;
				padding-right:$baseGap; 
				flex: 0 0 auto;
				width: 100%;
			}

			.phoneBg{
				// background: $secondary;
				   
				// text-align:center; 
				// border-radius:rem(30px);
				@include breakpoint(large) { 
					// width: 22.5rem;
    				height: 3.125rem;
    				
				}

				@include breakpoint(huge) { 
					// width: 20rem;
					height: 3.125rem;
					font-size: .9rem;
    				
				}
				
				
				a, span{
					color:$light; 
					font-weight:600;
					position:relative;
					text-decoration:none; 
					&:before{
						font-family: $iconFont;
						font-weight:normal; 
						font-size:rem(16px);
						margin-right: rem(5px); 
						content: map-get($iconMapFont, phone-icon);   
					}
				}
			} 
		}
		nav {
			ul {
				&.navi {
					@include breakpoint(large) {
						margin-top: 50px;
						padding-bottom: 30px;
					}
					@include breakpoint(huge) {
						// margin-top: 83px;
						padding-bottom: 5px;
					}
					li {
						padding:0; 

						&.kontakt, &.anfahrt, &.impressum,&.datenschutzerklaerung, &.partner, &.faq, &.facebook, &.whatsapp, &.instagram {
							display:none;  
						}
						&.veluxKonfigurator {
							@include breakpoint(tiny) {
								display: block;
							}
							@include breakpoint(medium) {
								display: none;
							}
						}
						a,span{
							font-size:rem(16px);  
							padding:0 !important;   
                			font-weight:300;
                			position: relative; 
                			display:block; 
							&:hover,&:focus{
								&.facebook  { 
									opacity: 0.7;
								}
							} 
                			&.facebook  { 
                				font-size:0;
                				width:rem(20px);
                				height:rem(20px); 
                				background:image-url('layout/facebook.jpg')no-repeat left top;
                				opacity: 1;   
                			}

						}
						&.hasSub {
							>span{
								&:after{
								content: map-get($iconMapFont, icon-line-arrow-down);  
								text-shadow: -.30px -.30px 0 transparent, 
                				.30px .30px transparent !important; 
                				transform:none; 
                				margin-left:0; 
								}
							}
							.sub { 
								width:rem(200px);
								li{ 
									padding: 0;
									border-bottom:1px solid $primary; 
									&:last-child{
										border:none; 
									}
									a{
										padding: .4rem 1rem !important;   
										font-size:rem(16px); 
									}
								}
							}
						} 
						&.nav-back {
						}
					}
				}
			}
		}
	}
}

// Quickbar Styles
////
#quickbar {
	@extend %animatedTransform;
	background-color: $secondary;
 	color: $barColor;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: rem($baseGap);
	z-index: 1000;

	@include breakpoint(giant) {
		margin-top: 60px;
	}

	@include breakpoint($break) { 
		display: none;
	}
	.phoneBg{
		position:relative;
		&:before{ 
			font-family: $iconFont;
			font-weight:normal; 
			font-size:rem(16px);
			margin-right: rem(5px); 
			content: map-get($iconMapFont, phone-icon);   
		} 
	}
	.video-btn{
		display: none; 
		@include breakpoint(tiny) { 
		margin-top: -3px;
		display: flex;
			a{
			position: relative;
			color: #fff;
			border-bottom: 1px solid #fff;
			z-index: 999999;
			}
		}
	}
}

.stretchNav {
	justify-content: space-between;
}