.marginBottom {
	margin-bottom: rem($baseLineHeight);
}

.highlight {
	color: $secondary;
}

.btn {
	@extend %animatedTransform;
	background: $secondary;
	border: none;
	color: $light;
	cursor: pointer;
	display: inline-block;
	font-size: rem($baseFontSize);
	line-height: rem($baseLineHeight);
	padding:rem(13px) rem(25px) rem(11px) rem(25px);
	// width: 100%;
	border-radius: rem(30px);
	text-align: center; 
	text-decoration: none;
	font-family: $mainFont;

	&:hover,
	&:active,
	&:focus {
		background: lighten($secondary, 15%);
		color: $light;
	}

	*[class^="icon"] {
		margin-left: 0.5rem;
		vertical-align: middle;
	}
}

.clear {
	clear: both;
	float: none;
}

.clearfix {
	@include pie-clearfix;
}

.hidden {
	display: none;
}

.hideText {
	@include hideText();
	display: inline-block;
	overflow: hidden;
	width: 0;
}

.floatLeft {
	float: left;
}

img.floatLeft {
	margin-right: 1rem;
}

.floatRight {
	float: right;
}

img.floatRight {
	margin-left: 1rem;
}

.fluid {
	height: auto;
	width: 100%;
}

.nonFluid {
	width: auto !important;
}

.alignLeft {
	text-align: left;
}

.alignRight {
	text-align: right;
}

.alignCenter {
	text-align: center;
}

.justifyCenter {
	justify-content: center;
}

.wide {
	width: 100%;
	display: block;
}

/* 21:9 video resolutions */
.videoContainer {

	&:not(.videoTag)
	{
		height: 0;
		overflow: hidden;
		padding-bottom: 42.6%;
		position: relative;
	
		iframe {
			border: none;
			height: 100%;
			position: absolute;
			width: 100%;
			left: 0;
			top: 0;
		}

	}


	video {
		width:100%;
		height:auto;
	}
}

body {
	&:before {
	$content: "default:" + $bpContext;
	width: str_length($content) * 6pt + $baseGap * 2;

	@each $point, $width in $gridMap {
		$content: $content +
		"...." +
		$point +
		":" +
		nth(map-get(map-get($gridMap, $point), width), 1);

		@include breakpoint($point) {
		width: str_length($content) * 6pt + $baseGap * 2;
		}
	}

	content: "#{$content}";
	display: none !important; /* Prevent from displaying. */
	}

	&.devmode:before,
	&.debug:before {
	background: $alert;
	border-color: $alert;
	border-style: solid;
	border-width: 0 rem($baseGap);
	color: $light;
	display: block !important;
	font-family: Courier;
	font-size: 10pt;
	left: 0;
	line-height: 2.5;
	overflow: hidden;
	position: absolute;
	right: 0;
	text-align: center;
	top: 0;
	white-space: nowrap;
	z-index: 99999;
	}
	&:hover:before {
	opacity: 0.5;
	pointer-events: none;
	}
}

// Animations
%animatedTransform {
	transition: 0.3s;
}


.notificationArea
{
	position: fixed;
	bottom:20px;
	left:20px;
	z-index:1000;

	> :not(:last-child)
	{
		margin-bottom:$baseGap;
	}

	.notification
	{
		position: relative;
		transition:300ms all ease-in-out;
		overflow: hidden;
		padding: 1em;
		background: $alert;
		font-size: 12px;
		line-height: 16px;
		box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
		width:290px;
		max-width:calc(100vw - 40px);
		transform-origin: left center;

		&, a
		{
			color: $light;
		}

		.close
		{
			position: absolute;
			top: 5px;
			right: 8px;
			cursor: pointer;
			color:#fff;
			font-size:1.2rem;
		}

		&.hide
		{
			opacity:0;
			visibility: hidden;
			transform:scale(0);
			max-height:0;
			height:0;
			padding-top:0;
			padding-bottom:0;
		}
	}
}

.popUpContainer {
 
	body:not(.cmsBackend) & {
		top: 0;
		left: 0;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100vh;
		overflow: hidden;
		transition: opacity .3s, visibility .3s;
		position: fixed;
		visibility: visible;
		opacity: 1;
		z-index: 1000000;
 
		.popUpContent {
            width: 100%;
			max-height: 95vh;
			max-width: calc(100% - #{rem(110px)}); // for the close label
	    	overflow: hidden;
	        overflow-y: auto;
	 
			@include breakpoint(giant) {
				max-width: 80%;
			}
	 
			@include breakpoint(full) {
				max-width: 70%;
			}

			.videoContainer {
				border-radius: 0;


				iframe {
					border-radius: 0;
				}
			}
		}
	}
 
	label {
		display:none;
 
		body:not(.cmsBackend) & {
			background: rgba(black, 0.8);
			display: flex;
			height: 100%;
			justify-content: flex-end;
			padding: rem($baseGap);
			position: absolute;
			right: 0;
			top: 0;
			width: 100%;
			z-index: -1;
	 
			i {
				cursor: pointer;
				display: block;
				height: rem(35px);
				width: rem(35px);
				position: relative;
				transition: background .3s;
	 
				&:hover {
					background: rgba(black, 0.8);
	 
					&:before, &:after {
						background: white;
					};
				}
	 
				&:before, &:after {
					content: '';
					display: block;
					position: absolute;
					top: 50%;
					left: 50%;
					width: rem(30px);
					height: rem(2px);
					background: #ddd;
				}
	 
				&:before {
					transform: translate(-50%,-50%) rotate(45deg);
				}
	 
				&:after {
					transform: translate(-50%,-50%) rotate(-45deg);
				}
			}
		}
	}
}
 
input[id^="popUpToggled"] {
	display: none;
 
	&:not(:checked) {
 
		+ {
 
			.popUpContainer {
				position: relative;
				opacity: 0;
				visibility: hidden;
				z-index: -1000000;
				transform:scale(0);
				pointer-events: none;
				width: 0;
				height: 0;
			}
		}
	}
}

.gap-bottom-1 {
	margin-bottom: 1rem;
}
.gap-bottom-2 {
	margin-bottom: 2rem;
}
.gap-bottom-3 {
	margin-bottom: 3rem;
}
.gap-bottom-4 {
	margin-bottom: 4rem;
}
.gap-bottom-5 {
	margin-bottom: 5rem;
}
.gap-top-1 {
	margin-top: 1rem;
}
.gap-top-2 {
	margin-top: 2rem;
}
.gap-top-3 {
	margin-top: 3rem;
}
.gap-top-4 {
	margin-top: 4rem;
}
.gap-top-5 {
	margin-top: 5rem;
}
.gap-top-8 {
	margin-top: 8rem;
}

.vertical-50 {
	transform: translateY(50%);
}

.videoPopUp {
  display: flex;
  align-items: center;
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100vh;
  background:rgba(black,0.8);
  z-index: 2000;
  transition:opacity 0.3s, visibility 0.3s, transform 0.3s, z-index 0.3s;

  &:not(.active) {
    opacity: 0;
    visibility: hidden;
    transform:scale(0);
    z-index: -999;
  }

  .popUpContainer {
      overflow: hidden;
      padding-bottom: 0;
      width: 550px;
      height: 300px;
      margin: 0 auto;

      iframe {
          height: 100%;
      }
  }
}

.popUpClose{
  position: absolute;
  top:rem(40px);
  right:rem(50px);
  color:$light;
  font-size: rem(40px);
  border:none;
  background:none;
  transition:color 0.3s;

  &:hover {
    cursor: pointer;
    color:$primary;
  }
}