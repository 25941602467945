// if you want to use more file types, write: $type: woff ttf woff2 (no comma separation!)

@include font-face(
$name: "icomoon",
$file: "icomoon",
$weight: 400,
$style: normal,
$type: woff
); 

@include font-face(
$name: "Poppins",
$file: "Poppins-Regular",
$weight: 400, 
$style: normal,
$type: woff
);

@include font-face(
$name: "Poppins",
$file: "Poppins-SemiBold",
$weight: 600,
$style: normal,
$type: woff
);

@include font-face(
$name: "Poppins",
$file: "Poppins-Light",
$weight: 300,
$style: normal,
$type: woff
);

@include font-face(
$name: "Poppins",
$file: "Poppins-Bold",
$weight: 700,
$style: normal,
$type: woff
);