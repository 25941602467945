// Relativ path to images folder (used in the image-url() function)
$imagePath: "../images/" !default;

// +++++ Font settings
// Font size of main content (in px)
$baseFontSize: 16px;

// Line height of main content (in px)
$baseLineHeight: 26px; 

// Headline font sizes (in px)
$h1Size: 34px;
$h2Size: 30px;
$h3Size: 24px;
$h4Size: 22px;
$h5Size: 18px;
$h6Size: 16px;

// Font stacks: $mainFont for simple content, $displayFont for headlines etc., $iconFont for icons
$mainFont:    "Poppins", sans-serif;
$displayFont: "Poppins", sans-serif;
$iconFont:   sans-serif;
$iconFont:   "icomoon";

// +++++ Design & Layout
// Colours for your design.
$primary:   #4b4b4b;
$secondary: #c4000c;
$light:     #fff;
$medium:    #F2F2F0;
$dark:      #262D3B;
$border:    #bbb;
$alert:     #c4000c; 

// Horizontal padding left and right of grid columns
$baseGap: 18px;

// Grid row max-width
$rowMaxWidth: 1620px; 

// Grid columns
$columnCount: 12;

// Standard border for your design
$baseBorder: 1px solid $border;

// Standart outline styles for focus states
$outline: 1px dotted $alert;

// Assume a different base font size for breakpoint em calculation (default: 16px)
$bpContext: 16px;

// +++++ Miscellaneous
// text-indent value when using hideText(): "right" = positive value, "left" = negative value
$hideTextDirection: "right";

$iconMap: (
    times: "×",
    minus: "-",
    angle-right: "❯",
    plus: "+",
    angle-up: "↑",
    exclamation: "!", 
);

 //EXAMPLE FOR USE WITH ICONFONT
 $iconMapFont: ( 
     phone-icon: "\ea81",
     icon-line-arrow-down:"\ea1d", 
     icon-circle-check:"\eaa7",  
     icon-pen:"\ea79",
     icon-map-marker-home:"\ea41", 
     icon-line-arrow-right:"\ea1f", 
     icon-play:"\ea29", 
     hand-icon:"\e9fb",  
 ); 


/*  Grid Map 
    Define your breakpoints and grid classes below.
    Only generate the gridclasses you really need to reduce filesize.
    example:
    tiny: ( <-- "breakpoint name"
        width:  em(480px, $bpContext), <-- "breakpoint width"
        cols: (5,6,12), <-- "columns" result generates .tiny-5, .tiny-6 and .tiny-12
        prefix: (2,4), <-- "prefix" result generates .tiny-prefix-2 and .tiny-prefix-4
        suffix: (6,8), <-- "suffix" result generates .tiny-suffix-6 and .tiny-suffix-8
    )
*/
$gridMap: (
    tiny: (
        width:  em(480px, $bpContext),
        cols: (6)
    ),
    small: (
        width:  em(640px, $bpContext),
        cols: (3,4,5,6,7,8) 
    ),
    medium: (
        width:  em(800px, $bpContext),
        cols: (4,6,3,10)  
    ),
    large: (
        width:  em(992px, $bpContext),
        cols: (2,3,4,5,6,7,8,10,12)  
    ),
    giant: (
        width:  em(1200px, $bpContext),
        cols: (3,9,8,4,6),
        suffix: (4)      
    ), 
    huge: (
        width:  em(1364px, $bpContext),
        cols: (3,4,7)
    ),
    full: (
        width:  em(1520px, $bpContext)
    ),
    extrafull: (
        width:  em(1720px, $bpContext)
    )
);