#pageWrap{
	overflow: hidden; 
}
// HEADER
//////////////////////////////

#pageHeader {
	position: relative;
	width: 100%;
	height: rem(560px);
	@include breakpoint(small) {
		height: rem(600px); 
	}  
	@include breakpoint(medium) {
		height: rem(700px); 
	}  
	@include breakpoint(large) {
		height: rem(860px); 
	}

	.brandDesktop{
	  display:block; 
	  background:$light;   
	  padding:rem(70px) 0 rem(20px);  
	  text-align: center;  
	  width:100%;
		@include breakpoint(large) { 
			display:none;
		}
	}  
	.branding {
		display: inline-block;
		img {
			&.nonFluid{
				width:70% !important; 
				@include breakpoint(small) { 
					width:auto !important;
				}
			}
		}
	}

	.claimCont{
		padding:rem(30px) 0 rem(20px); 
		body.index &{
			padding:rem(10px) 0 rem(40px); 
		}  
		@include breakpoint(medium) { 
			body.index &{
				position: relative;
				//top: rem(-60px);
			}
		}
		@include breakpoint(large) {
			padding: rem(300px) 0 rem(40px); 
			body.index &{
				position: unset;
				padding: rem(300px) 0 0; 
			} 
		}
		body.index &{  
			@include breakpoint(giant) {
				padding:rem(340px) 0 rem(125px);   
			}
		} 

		.EU {

			float: right;
			position: relative;
			top: 0;
			z-index: 99;

			.EUflag, .EUfond {

				display: inline-block;
				width: auto;

				@include breakpoint(small) {
					top: rem(199px);
				}

				@include breakpoint(large) {
					top: rem(140px);
					position: fixed;
				}

				@include breakpoint(giant) {
					top: .2rem;
				}

				@include breakpoint(huge) {
					top: rem(130px);
				}
			}
			
			.EUfond {

				height: rem(60px);
				right: rem(10px);
				margin-right: 0.5rem;
    			margin-bottom: 0.4rem;

    			@include breakpoint(medium) {
    				height: rem(68px);
    			}

    			@include breakpoint(large) {
    				margin-top: 4rem;
    			}
    			@include breakpoint(giant) {
    				margin-top: 12.5rem;
    			}
    			@include breakpoint(huge) {
					margin-top: 5rem;
				}

				@include breakpoint(full) {
					right: rem(20px);
					margin-top: 7rem;
					height: rem(100px);
				}
			}

			.EUflag {

				height: rem(40px);
				margin-top: rem(10px);
				margin-bottom: rem(16px);
    			margin-right: rem(8px);

    			@include breakpoint(medium) {
    				height: rem(48px);
    			}

    			@include breakpoint(large) {
    				right: rem(95px);
    				margin-top: 4rem;
    			}
    			@include breakpoint(giant) {
    				margin-top: 12.5rem;
    			}
    			@include breakpoint(huge) {
					margin-top: 5rem;
					
				}

				@include breakpoint(full) {
					margin-top: 7rem;
					right: rem(145px);
					height: rem(64px);
				}
			}

		}

		blockquote{
			color: #c4000c;
			background-color: rgba(255, 255, 255, 0.75);
			padding: 1rem;
			span{
				display:block; 
			}
			.smallText{
				font-size: rem(16px);
				letter-spacing: rem(1px);
				color: #c4000c; 
				margin-bottom:rem(10px); 
				@include breakpoint(tiny) { 
					font-size: rem(18px);
				} 
				@include breakpoint(small) { 
					letter-spacing: rem(3.5px);
					margin-bottom:rem(20px); 
				}
			}
			.bigText{
				font-size: rem(20px);
				font-weight:600; 
				line-height:rem(30px);  
				color: #c4000c;  
				margin-bottom:rem(10px);
				@include breakpoint(tiny) { 
					font-size: rem(30px); 
					line-height:rem(50px);
				}
				@include breakpoint(small) {
					font-size: rem(40px);
					line-height:rem(60px); 
					margin-bottom:rem(20px); 
				} 
				@include breakpoint(medium) {
					font-size: rem(52px);
					line-height:rem(70px); 
				} 
				@include breakpoint(huge) {
					font-size: rem(72px);
					line-height:rem(90px); 
				}
			}
			ul{
				display:none; 
				@include breakpoint(medium) {
					display:flex;
					margin-top:rem(50px);
				}
				li{
					
  					@include breakpoint(medium) {
  						flex-grow: 1;
  						text-align: center; 
  						position:relative;
  						color: $dark;
  					}
					&:before{
						font-family: $iconFont;  
						content: map-get($iconMapFont, icon-circle-check);
						margin-right: 4px;  
					}
					// &:after{
						
					// 	@include breakpoint(giant) {
					// 		content:'';
					// 		width:rem(50px);
					// 		height:1px;
					// 		background:rgba(255,255,255,0.3); 
					// 		top: 50%;
					// 		transform: translateY(-50%);
					// 		right: rem(-30px);    
					// 		position:absolute;
					// 	} 
					// 	@include breakpoint(huge) {
					// 		right: rem(-50px);
					// 		width:rem(90px);
					// 	}
					// }
					// &:last-child{
					// 	&:after{
					// 		display:none; 
					// 	}
					// }
					a{
					color: $dark;
						&:focus,
						&:hover,
						&:active {
							color: #c4000c;
							text-decoration: none;
						} 
					}
				}   
			}
		}
	}
	.fixedNav{
		position:absolute;
		right:0; 
		top:30%;
		height:rem(180px); 
		transform:translateY(-30%); 
		display:none;
		z-index:3; 
		@include breakpoint(giant) {
			position:fixed;
			pointer-events: none;
		}
		.index &{
			top:60%; 
			transform:translateY(-60%);
		}
		@include breakpoint(large) { 
			display:block; 
		}

		
		.phoneCont{
			background: $secondary;
			padding:rem(13px) rem(25px) rem(11px) rem(25px);   
			text-align:center; 
			// border-radius:rem(30px);
			margin-bottom:rem(12px); 
			// transform: translateX(72%); 
			// transition: all 0.3s ease-in-out;
			cursor:pointer; 
			pointer-events: all;
			&:hover,&:focus{
				transform: translateX(0);   	
				background:#a2000a; 
			} 
			a, span{
				color:$light; 
				font-weight:600;
				position:relative;
				text-decoration:none; 
				&:before{
					font-family: $iconFont;
					font-weight:normal; 
					font-size:rem(22px);  
					margin-right:rem(14px);     
					content: map-get($iconMapFont, phone-icon);   
				}
			}
		} 
		.kontaktCont{
			text-align: left; 
			a{
				background: $secondary;
				padding:rem(13px) rem(25px) rem(11px) rem(25px);  
				border-radius:rem(30px);
				margin-bottom:rem(10px);
				color:$light; 
				font-weight:600;
				position:relative;
				display:block;
				transform: translateX(72%);
				pointer-events: all;
				&:hover{
					text-decoration:none;
					background:#a2000a; 
				} 
				&:before{
					font-family: $iconFont;
					font-weight:normal; 
					font-size:rem(22px);  
					margin-right:rem(14px);    
					content: map-get($iconMapFont, icon-pen);   
				}
			} 
		}
		.mapCont{
			text-align: left;

			a{ 
				background: $secondary;
				padding:rem(13px) rem(25px) rem(11px) rem(25px);   
				border-radius:rem(30px);
				margin-bottom:rem(10px);
				color:$light; 
				font-weight:600; 
				position:relative;
				display:block;
				transform: translateX(72%); 
				pointer-events: all;
				&:hover{
					text-decoration:none;
					background:#a2000a; 
				}
				&:before{ 
					font-family: $iconFont; 
					font-weight:normal; 
					font-size:rem(22px);  
					margin-right:rem(14px);      
					content: map-get($iconMapFont, icon-map-marker-home); 
				}
			} 
		} 
	}
}
.teaserCont{
	background:#fbfbfb;
	padding:rem(50px) 0 rem(24px);  
	margin:rem(26px) 0 rem(50px);  
	display: inline-block;
	width: 100%;

	@include breakpoint(large) { 
		margin:rem(46px) 0 rem(70px);
		padding:rem(139px) 0 rem(103px); 
	}
	@include breakpoint(giant) { 
		margin:rem(103px) 0 rem(139px); 
	}

	.row {
		&.teaserBox{
			display: flex;
		    flex-wrap: nowrap;
		    overflow: visible;
		    overflow-x: auto;
			overflow-y: hidden;
		    -webkit-overflow-scrolling: touch;
		    @include breakpoint(large) {
		   		flex-wrap: wrap;  
		   		overflow-x: visible;
				overflow-y: visible;	
		    } 
		} 
	}
	.boxCont{
		margin-bottom:rem(36px); 
		text-align:center; 
		position:relative;
	}
	.headline{
		font-size: rem(25px);
		line-height: rem(38px); 
		color:$primary;
		font-weight:400;
		margin-bottom: rem(66px); 
		text-align:center; 
		display:block;
		@include breakpoint(small) { 
			font-size: rem(30px);
		}
		@include breakpoint(medium) { 
			font-size: rem($h1Size);
			line-height: rem(48px); 
		}
		span{
			display:block;
			font-size:rem(14px);  
			letter-spacing:rem(1px); 
			font-weight:600; 
			color:$primary;
			text-transform: uppercase; 
			@include breakpoint(small) { 
				font-size:rem(16px);
			}
			@include breakpoint(medium) {
				letter-spacing:rem(3px); 
				font-size:rem(18px);
			}   
		}
	}
	a{
		background:#fbfbfb; 
		box-shadow: 0px 0px 35px 7px rgba(6,6,6,0.1); 
		display:block;  
		 
		padding-bottom:rem(55px);
		&:hover,&:focus{
			text-decoration:none;
			.linkName{
				background:#a2000a;
				span{
					&:after{
						margin-left:rem(2px); 
					}
				} 
			} 
		}
		.imageBg{
			height:rem(218px); 
			margin-bottom:rem(50px); 
			border-top-right-radius: rem(5px);
			border-top-left-radius: rem(5px); 
		}
		&.dachdeckerei{
			.imageBg{
				background:image-url('teaser/dachdeckerei.jpg')no-repeat center top/cover;
			}
		}
		&.zimmerei{
			.imageBg{
				background:image-url('teaser/zimmerei.jpg')no-repeat center top/cover;
			}
		} 
		&.dachklempnerei{
			.imageBg{
				background:image-url('teaser/dachklempnerei.jpg')no-repeat center top/cover;
			}
		}
		&.terrassenbau{
			.imageBg{
				background:image-url('teaser/terrassenbau.jpg')no-repeat center top/cover;
			}
		}
		&.carports{
			.imageBg{
				background:image-url('teaser/carports.jpg')no-repeat center top/cover;
			}
		}
		&.dachcheckServiceplus{
			.imageBg{
				background:image-url('teaser/dachcheck- serviceplus.jpg')no-repeat center top/cover; 
			}
		}
		.linkHeadline{
			display:block;
			font-weight:600;
			color:$secondary; 
			margin-bottom:rem(27px); 
		}
		p{
			color:$primary;
			margin-bottom:rem(50px);
			padding:0 rem(18px); 
			@include breakpoint(huge) {
				padding:0 rem(57px);  
			}
		}
		.linkName{
			background:$secondary;
			border-bottom-right-radius: rem(5px);
			border-bottom-left-radius: rem(5px);  
			padding:rem(15px) rem(5px);
			color:$light;
			transition: all 0.3s ease-in-out;
			display:block;
			position:absolute;
			bottom:0;
			left:rem(18px);
			right:rem(18px);  
			span{ 
				font-weight:600; 
				display:inline-block;
				position:relative;
				&:after{
					font-family: $iconFont;
					font-weight:normal;  
					font-size:rem(16px);
					content: map-get($iconMapFont, icon-line-arrow-right); 
					transition: all 0.3s ease-in-out;  
				}
			}
		}
	}
	.leistungen &{
		padding:rem(26px) 0 rem(50px);
		margin:0; 
	}
}
// MAIN CONTENT
//////////////////////////////

main {
	display: block;
	padding:rem(50px) 0 rem(26px); 
	@include breakpoint(large) { 
		padding:rem(70px) 0 rem(46px);   
	} 
	@include breakpoint(giant) {  
		padding:rem(150px) 0 rem(126px); 
	}
	p,
	ol,
	ul,
	dl,
	table {
		@extend .marginBottom;
	}
	img,iframe{
		border-radius: rem(5px); 
	}
	ul:not(.unstyledList),
	dl:not(.unstyledList) {
		@extend .styledList;
	}
	.linkCont{
		margin:rem(24px) 0; 
		text-align:center; 
	}
	.googleMaps {
		@extend .videoContainer;

		@include breakpoint(medium) {
			height: rem(400px);
		}
	}
	.imageBox, .videoBox{
		@include breakpoint(huge) {  
			padding:0 rem(32px); 
		}
	}
	.videoBox{
		margin-bottom:rem(24px);
	}
	.videoContainer{
		 border-radius: 5px;
	}

	.spacer {
		margin-bottom: rem($baseLineHeight);

		@include breakpoint(medium) {
			margin-bottom: rem($baseLineHeight * 2);
		}
	}

	.boxCtn {
		background: $medium;
   		padding: rem(20px);
   		display: flex;
    	flex-direction: column;
    	justify-content: center;
    	align-items: center;
		border: rem(1px) solid $medium;
		transition: border 300ms ease-in-out;
		text-align: center;
		height: 100%;
		width: 100%;
		text-decoration: none;

    	.headline {
    		font-weight: 300;
    		color: $dark;
    		display: block;
    		margin-bottom: rem(15px);
    		font-size: rem(18px);
    		line-height: rem(28px);

    		@include breakpoint(huge) {
    			margin-top: rem(20px);
    			font-size: rem(22px);
    			line-height: rem(32px);
    		}
    	}

    	.link {
			display: inline-block; 
			font-weight: 800; 
			color: $secondary; 
			font-size: rem(14px); 
			line-height: rem(24px); 
			position: relative; 
			transition: color 300ms ease-in-out;
			margin-bottom: rem(15px);

			@include breakpoint(small) {
				 font-size: rem(16px); 
				 line-height: rem(26px); 
			}

			@include breakpoint(huge) {
				font-size: rem(15px); 
				line-height: rem(25px); 
			}

			&:before {
				content: ''; 
				width: 100%; 
				height: rem(1px); 
				background:$secondary; 
				position: absolute; 
				bottom: 0; 
				left: 0; 
				transition: background 300ms ease-in-out;

				@include breakpoint(huge) {
					bottom: rem(-3px)
				}
			}

			&:hover {
    			color: #000;

    			&:before { 
    				background: #000;
    			}
    		}
		}
	}
}

	.greyBox {
		background: $medium;
   		padding: rem(20px) rem(0);
   		display: flex;
    	flex-direction: column;
    	justify-content: center;
    	align-items: center;
		border: rem(1px) solid $medium;
		transition: border 300ms ease-in-out;
		text-align: center;
		height: 100%;
		width: 100%;
		text-decoration: none;
		// margin-bottom: rem(40px);

    	.headline {
    		font-weight: 300;
    		color: $dark;
    		display: block;
    		margin-bottom: rem(15px);
    		font-size: rem(18px);
    		line-height: rem(28px);

    		@include breakpoint(huge) {
    			margin-top: rem(20px);
    			font-size: rem(22px);
    			line-height: rem(32px);
    		}
    	}

    	.link {
			display: inline-block; 
			font-weight: 800; 
			color: $secondary; 
			font-size: rem(14px); 
			line-height: rem(24px); 
			position: relative; 
			transition: color 300ms ease-in-out;
			margin-bottom: rem(15px);

			@include breakpoint(small) {
				 font-size: rem(16px); 
				 line-height: rem(26px); 
			}

			@include breakpoint(huge) {
				font-size: rem(15px); 
				line-height: rem(25px); 
			}

			&:before {
				content: ''; 
				width: 100%; 
				height: rem(1px); 
				background:$secondary; 
				position: absolute; 
				bottom: 0; 
				left: 0; 
				transition: background 300ms ease-in-out;

				@include breakpoint(huge) {
					bottom: rem(-3px)
				}
			}

			&:hover {
    			color: #000;

    			&:before { 
    				background: #000;
    			}
    		}
		}
	}

.jobsContainer {
	display: flex;
	align-items: flex-start;
}

#privacyPolicy
{
	a
	{
		display: inline-block; 
		word-break: break-all;
	}
}

#unternehmensvideo {
	.textVideo{
		position: absolute;
	    text-align: center;
	    color: $secondary;
	    bottom: rem(40px); 
	    width: 100%;
	    cursor: pointer;
	    left: 0;
	    @include breakpoint(small) {
	    	bottom: rem(190px);
	    }
	    @include breakpoint(medium) {
	    	bottom: rem(230px);
	    }
	    @include breakpoint(large) {
	    	bottom: rem(40px);
	    } 
	    @include breakpoint(giant) {
	    	bottom: rem(180px);
	    }
	    @include breakpoint(huge) {
	    	bottom: rem(200px);
	    }
	    @include breakpoint(full) {
	    	bottom: rem(240px);
	    }
	    a, span{
	    	color:$dark; 
	    	font-size:rem(12px); 
	    	 @include breakpoint(tiny) { 
	    	 	font-size:rem(16px); 
	    	 }
	    	 @include breakpoint(large) { 
	    	 	font-size:rem(12px); 
	    	 }
	    	 @include breakpoint(giant) { 
	    	 	font-size:rem(56px); 
	    	 }
	    	&:hover, &:focus{
	    		color:$secondary; 
	    		text-decoration:none; 
	    	}
	    }
	    b{
	    	position:relative;
	    	display:inline-block; 
	    	&:before{
				font-family: $iconFont;
				font-weight:normal; 
				font-size:rem(18px);
				margin-right: rem(5px); 
				content: map-get($iconMapFont, icon-play);   
			}
	    }
	}
}

// FOOTER
//////////////////////////////

#pageFooter {
	.mapCont{
		height:rem(478px);
		iframe{
			height:rem(478px); 
			// filter: grayscale(100%); 
		}
	}
	// .FooterBg{
		 
	// 	display:none;
	// 	@include breakpoint(small) {
	// 		display:block; 
	// 		background:image-url('layout/tiny-bgFooter.jpg')no-repeat center center/cover;
	// 		height:rem(200px);  
	// 	}
	// 	@include breakpoint(medium) {
	// 		height:rem(400px);
	// 		background:image-url('layout/medium-bgFooter.jpg')no-repeat center center/cover;
	// 	}
	// 	@include breakpoint(large) {
	// 		height:rem(1061px); 
	// 		background:image-url('layout/bgFooter.jpg')no-repeat top center/cover; 
	// 	}
	// }
	.brandFooter{
		margin-bottom:rem(24px);

		@include breakpoint(large) {
			margin-bottom:rem(50px); 
			text-align:center;
		}
		@include breakpoint(giant) {  
			text-align:left;
			margin-bottom:0; 
		}
	}
	.Sitemap{
		padding:rem(50px) 0;  
		background:#fbfbfb;
		@include breakpoint(large) {
			padding:rem(107px) 0; 
		}
		.branding{
			margin-bottom:rem(20px); 
			display:inline-block; 
		} 
		dl.floatList{
			margin-bottom:rem(30px);
		}
		dl.floatList dt {
			width: 25%;
			font-size:rem(14px);
			@include breakpoint(tiny) {
			    width: 23%;
			     font-size:rem(16px);
			}
		}
		dl.floatList dd { 
			 width: 75%;  
			 font-size:rem(14px);
			@include breakpoint(tiny) {
			    width: 77%; 
			    font-size:rem(16px);
			}
		}
		b{
			display:block; 
		}
		.headline{
			display:block;
			font-size:rem(16px);  
			margin-bottom:rem(8px); 
			font-weight:600;
			color:$primary;
		}
		img {
			margin-top: rem($baseLineHeight * 1.5);
			margin-bottom: rem($baseLineHeight);

			@include breakpoint(giant) {
				margin-top: 0;
				margin-bottom: 0;
			}
		}
	}
	.SitemapNav{
		display:none;
		@include breakpoint(large) {
		 display:block;   
		}
	}
	.footer-extra {
		p {
			margin-bottom: 1.5rem;
		}
	}
	.navi { 
		li { 
			margin-bottom:rem(3px); 
			&.facebook, &.whatsapp, &.instagram, &.veluxKonfigurator{
				display:none; 
			}
			&:hover,&:focus,&.active{
				text-decoration:none;
				color:$secondary;
			}
			&.hasSub {
				> a{  
					&:after{
					content: map-get($iconMapFont, icon-line-arrow-down);  
						 text-shadow: -.30px -.30px 0 transparent, 
    				.30px .30px transparent !important;  
    				font-family: $iconFont;  
    				margin-left:0; 
					}
				}
				.sub{
					padding-left:rem(22px);
					margin-top:rem(3px);  	
					border-left: 1px solid #d8d8d8; 
				} 
			} 
			
			a, span{
				color:$primary; 
				&:hover,&:focus,&.active{
					text-decoration:none;
					color:$secondary; 
				}  
			}
		}
	}
}


.hand-icon {
	display: block;
	margin: rem($baseGap) auto 0;
	line-height: 100%;
	animation: handiconrotate 2s ease infinite;
	transition-timing-function: cubic-bezier(0.86, 0.01, 0.77, 0.78);
	transform-origin:50% 50%;
	position: relative;
	font-weight: 600;
	font-size: rem(14px);
	font-style: normal;
	color:$primary;  
	text-align: center;

	@keyframes handiconrotate {
		0% {
			transform:translateX(0);
		}
		25% {
			transform:translateX(5px);
		}
		50% {
			transform:translateX(-5px);
		}
		75% {
			transform:translateX(5px);
		} 
		100% {
			transform:translateX(0);
		}
	}
	@include breakpoint(large) {
		display: none;
	}
	@include breakpoint(medium) {
		margin: rem($baseGap*2) auto 0;	 
	}
	&:before {
		font-family: $iconFont;
		font-weight:normal;  
		font-size:rem(25px); 
		content: map-get($iconMapFont, hand-icon); 
		transform: rotate(270deg);   
		display:inline-block;  
	}
}


/* Link */
.tobi-zoom {
  border: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  display: block;
  position: relative;
  text-decoration: none;
}

.tobi-zoom img {
  display: block;
}

.tobi-zoom__icon {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  bottom: 0;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  line-height: 1;
  position: absolute;
  right: 0;
}

.tobi-zoom__icon svg {
  color: #fff;
  fill: none;
  height: 1.11111em;
  padding-bottom: 0.22222em;
  padding-left: 0.22222em;
  padding-right: 0.22222em;
  padding-top: 0.22222em;
  pointer-events: none;
  stroke-linecap: square;
  stroke-linejoin: miter;
  stroke-width: 2;
  stroke: #fff;
  width: 1.11111em;
}

/* Hide scrollbar if lightbox is displayed */
.tobi-is-open {
  overflow-y: hidden;
}

/* Lightbox */
.tobi {
  background-color: rgba(0, 0, 0, 0.8);
  bottom: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  contain: strict;
  font-size: 18px;
  left: 0;
  line-height: 1.5555555555555556;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1337;
}

.tobi[aria-hidden="true"] {
  display: none;
}

.tobi *,
.tobi *::before,
.tobi *::after {
  -webkit-box-sizing: inherit;
          box-sizing: inherit;
}

/* Slider */
.tobi__slider {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  will-change: transform;
}

.tobi__slider:not(.tobi__slider--is-dragging) {
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
          transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

@media screen and (prefers-reduced-motion: reduce) {
  .tobi__slider:not(.tobi__slider--is-dragging) {
    -webkit-transition: none;
    transition: none;
  }
}

.tobi__slider--is-draggable .tobi__slider__slide__content {
  cursor: -webkit-grab;
  cursor: grab;
}

.tobi__slider--is-dragging .tobi__slider__slide__content {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* Slide */
.tobi__slider__slide {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
}

/* Slide content */
.tobi__slider__slide__content > figure {
  margin: 0;
  position: relative;
}

.tobi__slider__slide__content > figure > img {
  display: block;
  height: auto;
  max-height: 85vh;
  max-width: 85vw;
  width: auto;
}

.tobi__slider__slide__content > figure > figcaption {
  background-color: rgba(255, 255, 255, 0.94);
  bottom: 0;
  color: #1a2a3a;
  display: block;
  left: 0;
  padding-bottom: 0.22222em;
  padding-left: 0.44444em;
  padding-right: 0.44444em;
  padding-top: 0.22222em;
  position: absolute;
  white-space: pre-wrap;
  width: 100%;
}

.tobi__slider__slide__content[data-type="html"] {
  max-height: 85vh;
  max-width: 85vw;
  overflow: hidden;
  overflow-y: auto;
  overscroll-behavior: contain;
}

.tobi__slider__slide__content[data-type="html"] video {
  cursor: auto;
  display: block !important;
  max-height: 85vh;
  max-width: 85vw;
}

.tobi__slider__slide__content[data-type="iframe"] {
  max-height: 85vh;
  max-width: 85vw;
  overflow: hidden;
  overflow-y: auto;
  overscroll-behavior: contain;
}

.tobi__slider__slide__content[data-type="iframe"] iframe {
  display: block !important;
  height: 85vh;
  width: 85vw;
}

.tobi__slider__slide__content[data-type="youtube"] {
  max-height: 85vh;
  max-width: 85vw;
  overflow: hidden;
  overflow-y: auto;
  overscroll-behavior: contain;
  width:30%; 
}

.tobi__slider__slide__content[data-type="youtube"] iframe {
  display: block !important;
}

/* Buttons */
.tobi > button {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
  border: 0.05556em solid transparent;
  color: #fff;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font: inherit;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  line-height: 1;
  margin: 0;
  opacity: .5;
  padding-bottom: 0.22222em;
  padding-left: 0.22222em;
  padding-right: 0.22222em;
  padding-top: 0.22222em;
  position: absolute;
  -ms-touch-action: manipulation;
      touch-action: manipulation;
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: opacity, transform;
  transition-property: opacity, transform, -webkit-transform;
  -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
          transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  will-change: opacity, transform;
}

@media screen and (prefers-reduced-motion: reduce) {
  .tobi > button {
    -webkit-transition: none;
    transition: none;
    will-change: opacity;
  }
}

.tobi > button svg {
  pointer-events: none;
  stroke: #fff;
  stroke-width: 1;
  stroke-linecap: square;
  stroke-linejoin: miter;
  fill: none;
  color: #fff;
}

.tobi > button:active, .tobi > button:focus, .tobi > button:hover {
  opacity: 1;
  -webkit-transform: scale(0.84);
          transform: scale(0.84);
}

@media screen and (prefers-reduced-motion: reduce) {
  .tobi > button:active, .tobi > button:focus, .tobi > button:hover {
    -webkit-transform: none;
            transform: none;
  }
}

.tobi > button.tobi__prev, .tobi > button.tobi__next {
  top: 50%;
  top: calc(50% - 2.22222em);
}

.tobi > button.tobi__prev svg, .tobi > button.tobi__next svg {
  height: 3.88889em;
  width: 3.88889em;
}

.tobi > button.tobi__prev {
  left: 0;
}

.tobi > button.tobi__next {
  right: 0;
}

.tobi > button.tobi__close {
  right: 0.27778em;
  top: 1em;
}

.tobi > button.tobi__close svg {
  height: 3.33333em;
  width: 3.33333em;
}

.tobi > button:disabled, .tobi > button[aria-hidden="true"] {
  display: none;
}

/* Counter */
.tobi__counter {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: transparent;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 1.11111em;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  left: 1em;
  line-height: 1;
  position: absolute;
  top: 2.22222em;
}

.tobi__counter[aria-hidden="true"] {
  display: none;
}

/* Loader */
.tobi-loader {
  display: inline-block;
  height: 5.55556em;
  left: calc(50% - 2.77778em);
  position: absolute;
  top: calc(50% - 2.77778em);
  width: 5.55556em;
}

.tobi-loader::before {
  -webkit-animation: spin 1s infinite;
          animation: spin 1s infinite;
  border-radius: 100%;
  border: 0.22222em solid #949ba3;
  border-top-color: #fff;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


.fondWrapper {
	position: relative;

	.verticalMiddle {
		@include breakpoint(giant) {
			position: absolute;
			width: 100%;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			padding-left: rem(32px);
		}
	}

	.h2 {
		padding-left: 0;
	}
}

.b-unternehmen{
	font-size: 24px; 
}

.m-top{
	margin-top: 0.5rem; 
}

.m-left{
	margin-left: 9%;
	@include breakpoint(tiny) {
		margin-left: 24%;
	}
	@include breakpoint(small) {
		margin-left: 31%;
	}
	@include breakpoint(medium) {
		margin-left: 34%;
	}
	@include breakpoint(large) {
		margin-left: 37%;
	}
	@include breakpoint(giant) {
		margin-left: 40%;
	}
	@include breakpoint(huge) {
		margin-left: 41%;
	}
	@include breakpoint(full) {
		margin-left: 42%;
	}
	@include breakpoint(extrafull) {
		margin-left: 43%;
	}
}
.phoneCont{
	display: flex;
	position:absolute;
	background-color: $secondary;
	.phoneBg {
		margin: .5rem;
	}
	@include breakpoint(large) { 
		max-width: 100%;
		flex-basis: 16.66667%;
		flex: 0 0 auto;			
	}
	@include breakpoint(huge) { 
		max-width: 64%;
		flex-basis: 16.66667%;
		flex: 0 0 auto;
		
	}
	.phoneBg{
		display: none; 

		@include breakpoint(large) { 
			// width: 21.5rem;
			height: 3.125rem;
			display: block; 
			padding-top: 11px;

		}
		//hier macht padding ab 1025px -> hardcoded
		@media(min-width: 1025px){
			padding-top: 11px;
		}
	}
	.video-btn{
		@include breakpoint(large, max) {
			display: none;
		}
		@include breakpoint(large) { 
		padding:rem(12px) rem(10px) rem(10px) rem(10px);   
		text-align:center; 
		@include breakpoint(large) { 
			// width: 25rem;
			height: 3.125rem;
			
		}
		@include breakpoint(huge) { 
			font-size: .9rem;
			
		}
		@include breakpoint(giant) {
			// width: 23rem;
		}
	}
		// @include breakpoint(huge) { 
		// 	padding:rem(15px) 0 rem(15px) 0; 
		// }
		  
		a, span{
			color:$light; 
			font-weight:600;
			position:relative;
			text-decoration:none; 
			
		}
	} 
}
.contact-navi{
	@include breakpoint(large) { 
		padding:rem(10px) rem(10px) rem(10px) rem(10px);   
		text-align:center; 
		@include breakpoint(large) { 
			// width: 15.5rem;
			height: 3.125rem;
			margin-top: 7px; 
			margin-left:0.5rem;
		}
		@include breakpoint(huge) { 
			font-size: .9rem;
		}
	}
		// @include breakpoint(huge) { 
		// 	padding:rem(15px) 0 rem(15px) 0; 
		// }
		  
	a, span{
		color:$light; 
		font-weight:600;
		position:relative;
		text-decoration:none; 
		
	}
	&:before{
		font-family: $iconFont;
		font-weight:normal; 
		font-size:rem(22px);  
		margin-right:rem(14px);    
		content: map-get($iconMapFont, icon-pen);
		color: #fff;   
	}
}
//////// Kontaktbutton //////////
.contact {
		background: $secondary;
		padding:rem(10px) rem(10px) rem(10px) rem(10px);   
		text-align:center; 
		border-radius:rem(30px);
		@include breakpoint(large) { 
			width: 12.5rem;
			height: 3.125rem;
			margin-top: 3.5rem; 
			margin-left:0.5rem;
			margin-bottom: 1rem;
		}
	a, span{
		color:$light; 
		font-weight:600;
		position:relative;
		text-decoration:none; 
		
	}
	&:before{
		font-family: $iconFont;
		font-weight:normal; 
		font-size:rem(22px);  
		margin-right:rem(14px);    
		content: map-get($iconMapFont, icon-pen);
		color: #fff;   
	}
}
.map-navi{
	@include breakpoint(large) { 
	padding:rem(10px) rem(10px) rem(10px) rem(10px);   
	text-align:center; 
	@include breakpoint(large) { 
		// width: 15.5rem;
		height: 3.125rem;
		margin-top: 7px;
		margin-left: 0.5rem;  
	}
	@include breakpoint(huge) { 
		font-size: .9rem;
	}
}
	// @include breakpoint(huge) { 
	// 	padding:rem(15px) 0 rem(15px) 0; 
	// }
	  
	a, span{
		color:$light; 
		font-weight:600;
		position:relative;
		text-decoration:none; 
		
	}
	&:before{ 
		font-family: $iconFont; 
		font-weight:normal; 
		font-size:rem(22px);  
		margin-right:rem(14px);      
		content: map-get($iconMapFont, icon-map-marker-home); 
		color: #fff;
	}
} 

.video-btn-2{
		background: $secondary;
		padding:rem(12px) rem(10px) rem(10px) rem(10px);   
		text-align:center; 
		border-radius:rem(30px);
		  
		a, span{
			color:$light; 
			font-weight:600;
			position:relative;
			text-decoration:none; 
			
		}
		@include breakpoint(tiny) {
			display:none;
		}
	} 

.ueberUns, .ueberUnsUnserUnternehmen, .ueberUnsUnserTeam {
	#pageHeader {
		background-image: url(/images/layout/tiny-ueber-uns-header.jpg);
		background-repeat: no-repeat;
		background-position: center rem(160px);
		background-size: cover;
		width: 100%; 
		@media (min-width: 50em) {
			background-image: url(/images/layout/medium-ueber-uns-header.jpg); 
		}


		@include breakpoint(giant) {
			background-image: url(/images/layout/ueber-uns.jpg);
			background-position: bottom rem(130px);
		}
	}
}

.ueberUnsUnserePartner {
	#pageHeader {
		background-image: url(/images/layout/unsere-partner-header.jpg);
		background-repeat: no-repeat;
		background-position: center rem(80px);
		background-size: cover;
		width: 100%; 
		@media (min-width: 50em) {
			background-image: url(/images/layout/unsere-partner-header.jpg); 
		}


		@include breakpoint(giant) {
			background-image: url(/images/layout/unsere-partner-header.jpg);
			background-position: bottom rem(70px);
		}
	}
}

.img-partner {
	height: 13rem;
	padding: 2rem;

	@include breakpoint (tiny) {
		height: 18rem;
	}
	@include breakpoint (small) {
		height: 23rem;
	}
	@include breakpoint (medium) {
		height: 13rem;
	}
	@include breakpoint (giant) {
		height: 20rem;
	}
}

.leistungenIndex, .leistungenDachklempnerei, .leistungenCarports
	#pageHeader {
		background-image: url(/images/layout/tiny-leistungen-header.jpg);
		background-repeat: no-repeat;
		background-position: center rem(190px);
		background-size: cover;

		width: 100%;
		height: rem(500px);

		@include breakpoint(large) {
			height: 800px;
		}

		@include breakpoint(giant) {
			height: 900px;
		}

		@media (min-width: 50em) {
			background-image: url(/images/layout/medium-leistungen-header.jpg); 
		}


		@media (min-width: 62em) {
			background-image: url(/images/layout/leistungen-header.jpg);
		}

	

}
.leistungenTerrassenbau  {
	#pageHeader {
		background: url(/images/layout/tiny-terrassen-header.jpg) no-repeat top center/cover;


		@media (min-width: 50em) {
			background: url(/images/layout/medium-terrassen-header.jpg) no-repeat top center/cover; 
		}


		@media (min-width: 62em) {
			background: url(/images/layout/terrassen.jpg) no-repeat top center/cover;
		}

	}

}
.leistungenDachdeckerei {
	#pageHeader {
		background: url(/images/layout/tiny-dachdeckerei-header.jpg) no-repeat top center/cover;


		@media (min-width: 50em) {
			background: url(/images/layout/medium-dachdeckerei-header.jpg) no-repeat top center/cover;
		}


		@media (min-width: 62em) {
			background: url(/images/layout/dachdeckerei-header.jpg) no-repeat top center/cover;
		}

	}
}
.leistungenZimmerei {
	#pageHeader {
		background: url(/images/layout/tiny-zimmerei-header.jpg) no-repeat top center/cover;


		@media (min-width: 50em) {
			background: url(/images/layout/medium-zimmerei-header.jpg) no-repeat top center/cover;
		}


		@media (min-width: 62em) {
			background: url(/images/layout/zimmerei-header.jpg) no-repeat top center/cover;
		}
	}
}
.dachcheckServiceplusPhp{
	#pageHeader {
		background: url(/images/layout/tiny-dachdeck-service-header.jpg) no-repeat top center/cover;


		@media (min-width: 50em) {
			background: url(/images/layout/medium-dachdeck-service-header.jpg) no-repeat top center/cover;
		}


		@media (min-width: 62em) {
			background: url(/images/layout/dachdeck-service-header.jpg) no-repeat top center/cover;
		}

	}
}


.jobsPhp {
	#pageHeader {
		background-image: url(/images/layout/tiny-jobs-header.jpg);
		background-repeat: no-repeat;
		background-position: center rem(190px);
		background-size: cover;

		@media (min-width: 50em) {
			background-image: url(/images/layout/medium-jobs-header.jpg);
		}


		@media (min-width: 62em) {
			background-image: url(/images/layout/jobs.jpg);
		}

	}
}
.index  {
	#pageHeader {
		background: url(/images/layout/tiny-index-header.jpg);
		background-repeat: no-repeat;
		background-position: center rem(100px);
		background-size: cover; 


		@media (min-width: 50em) {
			background: url(/images/layout/medium-index-header.jpg);
			background-repeat: no-repeat;
			background-position: center rem(130px);
			background-size: cover;  
		}


		@media (min-width: 62em) {
			background: url(/images/layout/index.jpg);
			background-repeat: no-repeat;
			background-position: center rem(110px);
			background-size: cover;
		}

	}
}
.eindrueckePhp  {
	#pageHeader {
		background: url(/images/layout/tiny-index-header.jpg) no-repeat top center/cover;


		@media (min-width: 50em) {
			background: url(/images/layout/medium-index-header.jpg) no-repeat top center/cover;
		}


		@media (min-width: 62em) {
			background: url(/images/layout/index.jpg) no-repeat top center/cover;
		}

	}
}
.partner, 
.kontakt, 
.anfahrt, 
.faq, 
.datenschutzerklaerung, 
.impressum {
	#pageHeader {
		background:image-url('layout/tiny-bgHeader.jpg')no-repeat center center/cover ;
		@include breakpoint(medium) { 
			background:image-url('layout/medium-bgHeader.jpg')no-repeat center center/cover ;
		} 
		@include breakpoint(large) {   
			background:image-url('layout/bgHeader.jpg')no-repeat top center/cover;
			top: 0;
		}
		blockquote {

		}
	}
}

.faq, .ueberUns, .ueberUnsUnserUnternehmen, .ueberUnsUnserTeam, .ueberUnsUnserePartner, .leistungenIndex, .leistungenDachdeckerei , .leistungenZimmerei, .leistungenDachklempnerei, .leistungenCarports, .leistungenTerrassenbau, .dachcheckServiceplusPhp, .eindrueckePhp, .jobsPhp {
	.claimCont{
	blockquote{
			display: none;
		}
	}
}

.margin-bottom-picture {

	@include breakpoint(medium) {
		margin-bottom: rem(60px);
	}
}

.margin-bottom-text {
	margin-bottom: rem(60px);
}

.center {
	text-align: center;
}

.center2 {
	text-align: center; 

	@include breakpoint(medium) {
		text-align: left;
	}
}

.wow {
	body.index & {
		visibility: hidden;
	}
}

.UV {
	cursor: pointer
}
.konfigurator { 
	display: inline-block;
	color: $light;
	font-weight: 600;
	padding:rem(12px) rem(10px) rem(10px) rem(10px);  
	cursor: pointer;
	// width: 100%;
	height: 3.125rem;
    margin-top: 0.5rem;
    margin-right: 0.5rem;
	@include breakpoint(small) {
		// width: 75%;
	}
	@include breakpoint(medium) {
		// width: 50%;
	}
	@include breakpoint(large) {
		// width: 45%;
	}

	@include breakpoint(huge) {
		// width: 41%;
		font-size: .9rem;
	}
	
	text-align: center;
	> label {
		cursor: pointer;
	}
}

.noBreakWord {
	display: none !important;
	@include breakpoint(tiny) {
		display: inline-block !important;
	}
}

// Define some variables for collapser usage
$collapser-class: ".collapser";
$collapser-gaps-lr: 1.2rem;
$collapser-gaps-tb: 1rem;
$collapser-color: $secondary;
$collapser-dark: $dark;

// Dont Edit Inside the mixin, overwrite styles in @include collapser
@mixin collapser {

    #{$collapser-class} {
        background: $collapser-color;
        padding: $collapser-gaps-tb 78px $collapser-gaps-tb $collapser-gaps-lr;
        position: relative;
        cursor: pointer;

        &:not(.collapser-initialized) {
            background: none;
            padding:0;

            &:after, &:before {
                display:none;
            }
        }

        &:after, &:before {
            background: $collapser-dark;
            width:25px;
            height: 3px;
            content:"";
            position: absolute;
            right: $collapser-gaps-lr;
            transition: all 300ms;
        }
        &:after {
            top: 50%;
        }
        &:before {
            transform: rotate(90deg);
            top:50%;
		}
		&.h2 {
			color: white;
		}

        &.active {
            &:before {
                transform: rotate(-135deg);
            }
            &:after {
                transform: rotate(-45deg);
            }
        }

        & + * {
            overflow:hidden;
            transition: max-height 300ms;

            &.limited {
                overflow-y:scroll;
                margin-bottom: 1rem;
            }
        }

        @content;
    }
}

// Add your custom shine here ;)
@include collapser {
	border-bottom: 3px solid #c4000c;
	background: none; 
}

// PopUp
.popUpContainer {
 
	body:not(.cmsBackend) & {
		top: 0;
		left: 0;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100vh;
		overflow: hidden;
		transition: opacity .3s, visibility .3s;
		position: fixed;
		visibility: visible;
		opacity: 1;
		z-index: 1000000;
 
		.popUpContent {
            width: 100%;
			background: $light;
			max-height: 90vh;
			max-width: calc(100% - #{rem(110px)}); // for the close label
	    	overflow: hidden;
	        overflow-y: auto;
			padding: 2rem 1.2rem 1.2rem 1.5rem;
			border-radius: 20px;
			border: 2px solid $primary;
			text-align: center;

			span {
				color: $secondary;
				font-weight: 700;
				font-size: 1.4rem;
				display: block;
				margin-bottom: 1rem;
			}

			img {
				max-width: 200px;
			}

			@include breakpoint(small) {
				padding: 2rem 1.5rem 1.5rem 1.5rem;
			}
			@include breakpoint(large) {
				max-width: 62%;
			}
			@include breakpoint(giant) {
				max-width: 56%;
				p {
					font-size: 1.1rem;
				}
			}
			@include breakpoint(huge) {
				max-width: 50%;
			}

		}
	}
 
	label {
		display:none;
 
		body:not(.cmsBackend) & {
			background: rgba(black, 0.8);
			display: flex;
			height: 100%;
			justify-content: flex-end;
			padding: rem($baseGap);
			position: absolute;
			right: 0;
			top: 0;
			width: 100%;
			z-index: -1;
	 
			i {
				cursor: pointer;
				display: block;
				height: rem(35px);
				width: rem(35px);
				position: relative;
				transition: background .3s;
	 
				&:hover {
					background: rgba(black, 0.8);
	 
					&:before, &:after {
						background: white;
					};
				}
	 
				&:before, &:after {
					content: '';
					display: block;
					position: absolute;
					top: 50%;
					left: 50%;
					width: rem(30px);
					height: rem(2px);
					background: #ddd;
				}
	 
				&:before {
					transform: translate(-50%,-50%) rotate(45deg);
				}
	 
				&:after {
					transform: translate(-50%,-50%) rotate(-45deg);
				}
			}
		}
	}
}

.popUpLabel {
 	display: none;
}
 
input[id^="popUpToggled"] {
	display: none;
 
	&:not(:checked) {
 
		+ {
 
			.popUpContainer {
				position: relative;
				opacity: 0;
				visibility: hidden;
				z-index: -1000000;
				transform:scale(0);
				pointer-events: none;
				width: 0;
				height: 0;
			}
		}
	}
}

.teamcenter {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.mBottom{
	margin-bottom: 1rem;
	display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.teambg {
	background-color: #fff;
	margin-bottom: rem(20px);
	padding-bottom: rem(5px);
	border-bottom-right-radius: rem(5px);
	border-bottom-left-radius: rem(5px);
	width: -webkit-fill-available;

	.btn {
		width: 92%;
		border-radius: 0;
	}

	span {
		height: 2.5rem;
	}
}

.teamimg {
	border-top-right-radius: rem(5px);
	border-top-left-radius: rem(5px);
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
	width:100%;
	max-height: 410px;
	object-fit: cover;
	object-position: top;
}

.heightpartner {
	height: 2rem;
}

.hprimary {
	color: $primary;
}
.nomargin {
	margin: 0 !important;
}
.faq-fragen .active {
	margin-bottom: rem(30px) !important;
}

.bg-partner {
	background: #fff;
	padding: rem(50px) rem(20px);
	
	.btn {
		width: 100%;
		border-radius: 0;
	}
}

.text-partner {
	height: 10rem;
	overflow-y: auto;
	@include breakpoint(medium) {
		height: 23rem;
	}
	@include breakpoint(large) {
		height: 18rem;
	}
	@include breakpoint(huge) {
		height: 12rem;
	}
}

.red-nav {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin: 0.5rem !important;
}

.end {
	display: none;
	justify-content: end;
	max-width: 90rem;

	@include breakpoint (large) {
		display: flex;
	}
}

#pageNavi .col {
	@include breakpoint (large) {
		padding: rem(16px) rem(18px) rem(0) rem(50px);
	}

	@include breakpoint (huge) {
		padding: rem(88px) rem(0) rem(0) rem(0);
	}

	.branddesktop {
		@include breakpoint (large) {
			padding: rem(16px) rem(18px) rem(0) rem(5px);
		}
	}
}

.branddesktop {
	margin: 1rem 0rem 0 0;
	padding: rem(28px) rem(0) rem(0) rem(12px) !important;

	@include breakpoint (giant) {
		padding: rem(4px) rem(0) rem(0) rem(8px) !important;
	}

	@include breakpoint (huge) {
		padding: rem(76px) rem(0) rem(0) rem(12px) !important;
		margin-right: 4rem;
		width: 400px;
	}
}

.max-nav {
	@include breakpoint(huge) {
		display: flex;
		justify-content: center;
		max-width: 80%;
		margin: auto;
	}
}

.icon-flex {
	display: flex;
	align-items: center;
}

.job-spacer {
	margin: rem($baseLineHeight*3) auto;

	@include breakpoint(medium) {
		margin: rem($baseLineHeight*5) auto 0 auto;
	}
}

.index-jobs {
	background-color: $secondary;
	padding: rem(80px) 0;
	
	.h2{
		color: $light;

		span{
			color: $light;
			margin-top: 5px;
		}

		&:after {
			background: $medium;
		}
	}

	.btn {
		background: $medium;
		color: $primary;

		&:hover {
			color: $light;
			background-color: #a2000a;
		}
	}

	.btn-left {
		display: flex;
		justify-content: center;

		@include breakpoint(large) {
			justify-content: flex-end;
		}
	}

	.btn-right {
		@include breakpoint(large, max) {
			display: flex;
			justify-content: center;
			margin-top: 20px;
		}
	}
}

.footer-left {
	@include breakpoint (large, max) {
		margin-left: 18px;
	}
}

body.ueberUnsUnserTeam  {
	main {
		padding-bottom: 0;
	}
}